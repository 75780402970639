import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { cx } from "utilities";
import { useGroupColumns } from "./useGroupColumns";
import styles from "pages/wms/shared/Wms.module.css";
import { useFilters } from "hooks/useFilters";
import { useStateModal } from "hooks";
import {
  AwaitingTransportDetails,
  AwaitingTransportGroupListItem,
  GroupStatus,
} from "api/wms/awaiting-transports/models";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { LineItemsDetailsModal } from "./lineItemsDetailsModal/LineItemsDetailsModal";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";

interface Props {
  awaitingTransport: AwaitingTransportDetails;
}

export const OrdersSection = ({ awaitingTransport }: Props) => {
  const { searchParams, setFilter } = useFilters({
    page: 1,
    awaitingTransport: awaitingTransport.id,
  });
  const {
    data: groups,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = wmsAwaitingTransportsActions.useGetAwaitingTransportGroups(searchParams);

  const columns = useGroupColumns(groups);
  const lineItemDetailsModal = useStateModal<AwaitingTransportGroupListItem>();

  return (
    <>
      <RightPanelSection title="Zadeklarowane paczki">
        <div className="pb-3">
          <Table<AwaitingTransportGroupListItem>
            rows={groups}
            columns={columns}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
            pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
            onRowClick={row =>
              lineItemDetailsModal.open(groups.find(group => group.id === row.id)!)
            }
            overrides={() => {
              return {
                hideHeader: !groups.length,
                noResultComponent: (
                  <div className="mt-2">
                    <EmptySection label="Brak paczek" />
                  </div>
                ),
                row: row => {
                  return {
                    className: cx("cursor-pointer", {
                      [styles.finishedItem]: row.status === GroupStatus.FINISHED,
                      [styles.inProgressItem]: row.status === GroupStatus.IN_PROGRESS,
                    }),
                  };
                },
              };
            }}
          />
        </div>
      </RightPanelSection>
      {lineItemDetailsModal.isOpen && lineItemDetailsModal.state && (
        <LineItemsDetailsModal
          close={lineItemDetailsModal.close}
          awaitingTransport={awaitingTransport}
          awaitingTransportGroup={lineItemDetailsModal.state}
        />
      )}
    </>
  );
};
