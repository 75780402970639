import { Table } from "components/miloDesignSystem/molecules/table";
import { NormalizedLineItem, useLineItemColumns } from "./useLineItemColumns";
import { useMemo } from "react";
import { cx, queryString } from "utilities";
import { useQuery } from "hooks";
import { DocumentType, Filters } from "../LineItemSection";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { whEntriesActions } from "api/wh-entries/actions";
import { LineItemKind } from "api/wh-entries/enums";
import { useNavigate } from "hooks/useNavigate";

interface Props {
  documentType: DocumentType;
  filters: Filters;
  setFilter: (key: keyof Filters, value: Filters[keyof Filters]) => void;
}

export const LineItemTable = ({ documentType, filters, setFilter }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data, isLoading, isFetching, error, pagination } = whEntriesActions.useLineItems(
    queryString.stringify({ ...filters, whEntry: panelId }),
  );
  const navigate = useNavigate();

  const normalizedData: NormalizedLineItem[] = useMemo(() => {
    if (!data) return [];
    return data.map(row => {
      const subRows: NormalizedLineItem[] = (row.packages || []).map(packageRow => {
        return {
          id: packageRow.code,
          code: packageRow.code,
          name: packageRow.name,
          numberOfScannedPackagesGroups: null,
          kind: LineItemKind.PACKAGE,
          numberOfPackages: { total: packageRow.total, received: packageRow.received },
          amount: packageRow.amount,
          order: null,
          subRows: [],
          connectedWithAnotherWhEntry: packageRow.connectedWithAnotherWhEntry,
        } as NormalizedLineItem;
      });
      return {
        id: row.id,
        name: row.name,
        code: row.code,
        packages: row.packages,
        kind: row.kind,
        order: row.order,
        amount: row.amount,
        numberOfScannedPackagesGroups: row.numberOfScannedPackagesGroups,
        numberOfPackages: row.numberOfPackages,
        connectedWithAnotherWhEntry: [],
        subRows: row.kind === LineItemKind.PACKAGE ? [] : subRows,
      };
    });
  }, [data]);
  const tableProps = useTableFeatureConnector({
    rows: normalizedData,
  });

  const columns = useLineItemColumns(filters, documentType);

  return (
    <div className="d-flex flex-column">
      <Table<NormalizedLineItem>
        expandable
        columns={columns}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        isLoading={isLoading || isFetching}
        onRowClick={row => {
          if (row.kind === LineItemKind.PACKAGE) navigate(`/finances/packages/${row.code}`);
        }}
        error={error}
        uiSchema={{ cell: { height: "36" }, header: { backgroundColor: "neutralWhite100" } }}
        {...tableProps}
        overrides={() => {
          return {
            ...tableProps.overrides?.(),
            row: row => {
              return {
                className: cx(
                  {
                    "success-border": row.numberOfPackages.received === row.numberOfPackages.total,
                    "disabled-row": row.numberOfPackages.received === 0 && filters.showAwaiting,
                    "info-border":
                      row.subRows.some(subRow => subRow.connectedWithAnotherWhEntry.length > 0) &&
                      filters.showConnectedWithAnotherWhEntry,
                  },
                  tableProps.overrides?.().row?.(row).className,
                ),
              };
            },
          };
        }}
      />
    </div>
  );
};
