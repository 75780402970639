import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { ProductLightWithDeleted } from "./models";
import { productsKeys } from "api/keys";
import { productsKeysNew } from "./keys";
import { ProductEntity } from "./models";
import { Pagination } from "api/types";

const getProductIndexesPrice = (search: string): ApiFetcher<Record<number, string>> => ({
  key: productsKeysNew.productIndexesPrice(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/get-product-indexes-price" + search,
    }),
});

const getProductIndexes = (search: string): ApiFetcher<Record<string, number>> => ({
  key: productsKeysNew.productIndexes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/get-product-indexes" + search,
    }),
});

export const getProductEntity = (id: number | string): ApiFetcher<ProductEntity> => ({
  key: productsKeysNew.productEntity(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/separated/" + id,
    }),
});

const getProductsLightWithDeleted = (
  search: string = "",
): ApiFetcher<Pagination<ProductLightWithDeleted>> => ({
  key: productsKeys.productsWithDeleted(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/light-with-deleted" + search,
    }),
});

export const productsApi = {
  getProductIndexesPrice,
  getProductIndexes,
  getProductEntity,
  getProductsLightWithDeleted,
};
