import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import { productsApi } from "./api";

const useGetProductIndexesPrice = createApiQuery(productsApi.getProductIndexesPrice);
const useGetProductIndexes = createApiQuery(productsApi.getProductIndexes);
const useGetProductEntity = createApiQuery(productsApi.getProductEntity);
const useProductsLightWithDeleted = createPaginatedApiQuery(
  productsApi.getProductsLightWithDeleted,
);

export const productsActions = {
  useGetProductIndexesPrice,
  useGetProductIndexes,
  useGetProductEntity,
  useProductsLightWithDeleted,
};
