import { createPaginatedKey } from "api/keys";

export const productsKeysNew = {
  product: (id: string) => ["product", id],
  manufacturers: createPaginatedKey("manufacturers"),
  categories: {
    list: createPaginatedKey("productCategories"),
  },
  productIndexesPrice: createPaginatedKey("productIndexesPrice"),
  productIndexes: createPaginatedKey("productIndexes"),
  productEntity: (id: string) => ["productEntity", id],
};
